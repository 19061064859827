// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import Recoil from "recoil";
import { userState } from "./../../store/user";

function AdminNavbar({ theme }) {
  // function that on mobile devices makes the search open
  const [user, setUser] = Recoil.useRecoilState(userState);
  // const openSearch = () => {
  //   document.body.classList.add("g-navbar-search-showing");
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-showing");
  //     document.body.classList.add("g-navbar-search-show");
  //   }, 150);
  //   setTimeout(function () {
  //     document.body.classList.add("g-navbar-search-shown");
  //   }, 300);
  // };
  // // function that on mobile devices makes the search close
  // const closeSearch = () => {
  //   document.body.classList.remove("g-navbar-search-shown");
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-show");
  //     document.body.classList.add("g-navbar-search-hiding");
  //   }, 150);
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-hiding");
  //     document.body.classList.add("g-navbar-search-hidden");
  //   }, 300);
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-hidden");
  //   }, 500);
  // };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar></Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar bg-gradient-red avatar-sm rounded-circle">
                      <i className="ni ni-single-02" />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {user.email}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setUser({ role: [] });
                      sessionStorage.removeItem("token");
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;

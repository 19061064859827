/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Alternative from "views/pages/dashboards/Alternative.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import User from "views/pages/dashboards/User.js";
import CreateExercices from "views/pages/exercices/CreateExercices.js";
import EditExercices from "views/pages/exercices/EditExercices.js";
import Exercices from "views/pages/exercices/Exercices.js";
import Routine from "views/pages/routines/Routine.js";

const routes = [
  {
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    path: "/dashboard",
    component: Dashboard,
  },
  {
    collapse: true,
    name: "Exercices",
    icon: "ni ni-collection text-orange",
    views: [
      {
        path: "/exercices",
        name: "Mes exercices",
        miniName: "G",
        component: Exercices,
      },
      {
        path: "/create-exercice",
        name: "Créer un exercices",
        miniName: "V",
        component: CreateExercices,
      },
      {
        path: "/editexercice/:id",
        name: "Créer un exercices",
        miniName: "V",
        component: EditExercices,
        hidden: true,
      },
      {
        path: "/User/:id",
        name: "Créer un exercices",
        miniName: "V",
        component: User,
        hidden: true,
      },
    ],
  },
  {
    name: "Routines",
    icon: "ni ni-planet text-primary",
    path: "/routine",
    component: Routine,
  },

  // {
  //   collapse: true,
  //   name: "Examples",
  //   icon: "ni ni-ungroup text-orange",
  //   state: "examplesCollapse",
  //   views: [
  //     {
  //       path: "/pricing",
  //       name: "Pricing",
  //       miniName: "P",
  //       component: Pricing,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/register",
  //       name: "Register",
  //       miniName: "R",
  //       component: Register,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/lock",
  //       name: "Lock",
  //       miniName: "L",
  //       component: Lock,
  //       layout: "/auth",
  //     },
  //     {
  //       path: "/timeline",
  //       name: "Timeline",
  //       miniName: "T",
  //       component: Timeline,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/profile",
  //       name: "Profile",
  //       miniName: "P",
  //       component: Profile,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/rtl-support",
  //       name: "RTL Support",
  //       miniName: "RS",
  //       component: RTLSupport,
  //       layout: "/rtl",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Components",
  //   icon: "ni ni-ui-04 text-info",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       miniName: "B",
  //       component: Buttons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/cards",
  //       name: "Cards",
  //       miniName: "C",
  //       component: Cards,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/grid",
  //       name: "Grid",
  //       miniName: "G",
  //       component: Grid,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       miniName: "N",
  //       component: Notifications,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       miniName: "I",
  //       component: Icons,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       miniName: "T",
  //       component: Typography,
  //       layout: "/admin",
  //     },
  //     {
  //       collapse: true,
  //       name: "Multi Level",
  //       miniName: "M",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "#pablo",
  //           name: "Third level menu",
  //           component: () => {},
  //           layout: "/",
  //         },
  //         {
  //           path: "#pablo",
  //           name: "Just another link",
  //           component: () => {},
  //           layout: "/",
  //         },
  //         {
  //           path: "#pablo",
  //           name: "One last link",
  //           component: () => {},
  //           layout: "/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "ni ni-single-copy-04 text-pink",
  //   state: "formsCollapse",
  //   views: [
  //     {
  //       path: "/elements",
  //       name: "Elements",
  //       miniName: "E",
  //       component: Elements,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/components",
  //       name: "Components",
  //       miniName: "C",
  //       component: Components,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/validation",
  //       name: "Validation",
  //       miniName: "V",
  //       component: Validation,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "ni ni-align-left-2 text-default",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/tables",
  //       name: "Tables",
  //       miniName: "T",
  //       component: Tables,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/sortable",
  //       name: "Sortable",
  //       miniName: "S",
  //       component: Sortable,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/react-bs-table",
  //       name: "React BS Tables",
  //       miniName: "RBT",
  //       component: ReactBSTables,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "ni ni-map-big text-primary",
  //   state: "mapsCollapse",
  //   views: [
  //     {
  //       path: "/google",
  //       name: "Google",
  //       miniName: "G",
  //       component: Google,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/vector",
  //       name: "Vector",
  //       miniName: "V",
  //       component: Vector,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "ni ni-archive-2 text-green",
  //   component: Widgets,
  //   layout: "/admin",
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "ni ni-chart-pie-35 text-info",
  //   component: Charts,
  //   layout: "/admin",
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "ni ni-calendar-grid-58 text-red",
  //   component: Calendar,
  //   layout: "/admin",
  // },
];

export default routes;

import moment from "moment";
import React from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
// import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactCountryFlag from "react-country-flag";

import CardsHeader from "components/Headers/CardsHeader.js";
import { Link } from "react-router-dom";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Afficher{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        utilisateurs.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function Dashboard() {
  const [disk, setDisk] = React.useState({});
  const [users, setUsers] = React.useState([]);
  // const [alert, setAlert] = React.useState(null);
  const componentRef = React.useRef(null);

  // const copyToClipboardAsTable = (el) => {
  //   var body = document.body,
  //     range,
  //     sel;
  //   if (document.createRange && window.getSelection) {
  //     range = document.createRange();
  //     sel = window.getSelection();
  //     sel.removeAllRanges();
  //     try {
  //       range.selectNodeContents(el);
  //       sel.addRange(range);
  //     } catch (e) {
  //       range.selectNode(el);
  //       sel.addRange(range);
  //     }
  //     document.execCommand("copy");
  //   } else if (body.createTextRange) {
  //     range = body.createTextRange();
  //     range.moveToElementText(el);
  //     range.select();
  //     range.execCommand("Copy");
  //   }
  //   setAlert(
  //     <ReactBSAlert
  //       success
  //       style={{ display: "block", marginTop: "-100px" }}
  //       title="Good job!"
  //       onConfirm={() => setAlert(null)}
  //       onCancel={() => setAlert(null)}
  //       confirmBtnBsStyle="info"
  //       btnSize=""
  //     >
  //       Copied to clipboard!
  //     </ReactBSAlert>
  //   );
  // };

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dashbord/disk`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setDisk(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    fetch(`${process.env.REACT_APP_API_URL}/dashbord/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setUsers(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {alert}
      <CardsHeader
        name="Default"
        parentName="Dashboards"
        disk={disk}
        users={users}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Liste des utilisateur</h3>
                <p className="text-sm mb-0"></p>
              </CardHeader>
              <ToolkitProvider
                data={users}
                keyField="email"
                columns={[
                  {
                    dataField: "email",
                    text: "Email",
                    sort: true,
                  },
                  {
                    dataField: "date_subscription",
                    text: "Date d'abonnement",
                    sort: true,
                    formatter: (cellContent, row) => {
                      if (row.subscription) {
                        return (
                          <Badge color="primary">
                            {moment
                              .unix(row.subscription.start_date)
                              .format("DD/MM/YYYY")}
                          </Badge>
                        );
                      }
                      if (row.premium)
                        return <Badge color="primary">Abonnement forcé</Badge>;
                      return <Badge color="warning">Non abonné</Badge>;
                    },
                  },
                  {
                    dataField: "lang",
                    text: "Language",
                    sort: true,
                    formatter: (cellContent, row) => {
                      if (row.lang === "fr") {
                        return <ReactCountryFlag countryCode="FR" svg />;
                      }
                      return <ReactCountryFlag countryCode="GB" svg />;
                    },
                  },
                  {
                    dataField: "option",
                    text: "Option",
                    sort: true,
                    formatter: (cellContent, row) => {
                      console.log(row);
                      return (
                        <Link to={`/user/${row._id}`}>
                          <Button
                            className="btn-icon btn-2"
                            color="primary"
                            type="button"
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-settings-gear-65" />
                            </span>
                          </Button>
                        </Link>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={12} sm={6}>
                          <ButtonGroup>
                            <Button
                              className="buttons-copy buttons-html5"
                              color="default"
                              size="sm"
                              id="get-email"
                              onClick={() => {
                                const usersEmail = users.map((user) => {
                                  return user.email + "\n";
                                });
                                var blob = new Blob([usersEmail.join("")], {
                                  type: "text/plain;charset=utf-8",
                                });
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.style.display = "none";
                                a.href = url;
                                // the filename you want
                                a.download = "emails.txt";
                                document.body.appendChild(a);
                                a.click();
                                window.URL.revokeObjectURL(url);
                              }}
                            >
                              <span>Extraire les emails</span>
                            </Button>
                          </ButtonGroup>
                          <UncontrolledTooltip
                            placement="top"
                            target="get-email"
                          >
                            Telecharger la liste total des emails
                          </UncontrolledTooltip>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-right"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;

// reactstrap components
import React, { useState } from "react";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
// react icons import
import { BsArrowRight } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import "../../../../assets/css/routine.css";

let key = 0;
function Elements(props) {
  const [items, setItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [exercices, setExercices] = React.useState([]);
  const [canAddExercice, setCanAddExercice] = React.useState(true);
  const [idRoutine, setIdRoutine] = React.useState(null);
  const [exerciceLoad, setExerciceLoad] = React.useState(false);

  const [nameFr, setNameFr] = React.useState("");
  const [nameEn, setNameEn] = React.useState("");
  const [descriptionFr, setDescriptionFr] = React.useState("");
  const [descriptionEn, setDescriptionEn] = React.useState("");

  // use ref
  const select = React.useRef(null);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/exercice?lang=fr`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setExercices(
            data.data.all.map((exercice) => {
              if (
                props.routine &&
                props.routine.exercices.includes(exercice.id)
              ) {
                return {
                  value: exercice.id,
                  label: exercice.name,
                  isDisabled: true,
                  data: exercice,
                };
              }
              return {
                value: exercice.id,
                label: exercice.name,
                data: exercice,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.routine]);

  React.useEffect(() => {
    if (props.routine && exercices.length > 0 && !exerciceLoad) {
      setIdRoutine(props.routine._id);
      setNameFr(props.routine.name.fr);
      setNameEn(props.routine.name.en);
      setDescriptionFr(props.routine.description.fr);
      setDescriptionEn(props.routine.description.en);
      let finalItems = [];
      for (let i = 0; i < props.routine.exercices.length; i++) {
        const data = exercices.find(
          (exercice) => exercice.value === props.routine.exercices[i]
        );
        finalItems = [
          ...finalItems,
          {
            Label: data.label,
            value: data.value,
            id: key + 1,
            data: data.data,
          },
        ];
        key = key + 1;
      }
      setItems(finalItems);
      setExerciceLoad(true);
    }
  }, [props, exercices]);

  const handleRLDDChange = (reorderedItems) => {
    setItems(reorderedItems);
  };

  const handleAddExercice = () => {
    if (select.current.getValue().length === 0) {
      return;
    }
    if (items.length > 7) {
      return;
    }
    setItems([
      ...items,
      {
        Label: selectedOption.label,
        value: selectedOption.value,
        id: key + 1,
        data: selectedOption.data,
      },
    ]);
    setExercices(
      exercices.map((exercice) => {
        if (exercice.value === selectedOption.value) {
          return { ...exercice, isDisabled: true };
        }
        return exercice;
      })
    );
    key = key + 1;
    select.current.clearValue();
    if (items.length === 7) {
      setCanAddExercice(false);
    }
  };

  const handleDeleteItem = (e) => {
    setCanAddExercice(true);
    const id = e.value;
    setItems(items.filter((item) => item.value !== id));
    setExercices(
      exercices.map((exercice) => {
        if (exercice.value === id) {
          return { ...exercice, isDisabled: false };
        }
        return exercice;
      })
    );
  };

  const SaveRoutine = () => {
    const routine = {
      idRoutine: idRoutine,
      order: props.order,
      name: {
        fr: nameFr,
        en: nameEn,
      },
      description: {
        fr: descriptionFr,
        en: descriptionEn,
      },
      exercices: items.map((item) => {
        return {
          id: item.value,
        };
      }),
    };
    const method = idRoutine ? "PUT" : "POST";
    fetch(`${process.env.REACT_APP_API_URL}/routine`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify(routine),
    })
      .then((res) => res.json())
      .then((data) => {
        props.handleCancel();
      });
  };

  return (
    <>
      <Card className="mb-4" key={idRoutine}>
        <CardHeader>
          <h3 className="mb-0">{props.name}</h3>
          {props.routine && (
            <Button
              color="danger"
              className="edit"
              size="sm"
              onClick={() => props.handleDelete(props.routine._id)}
            >
              <MdDelete />
              &nbsp;&nbsp;Supprimer
            </Button>
          )}
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="form-control-label" htmlFor="input-name">
                  Nom de la routine français
                </Label>
                <Input
                  value={nameFr}
                  onChange={(e) => setNameFr(e.target.value)}
                  className="form-control-alternative"
                  id="input-namefr"
                  placeholder="Nom de la routine français"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="form-control-label" htmlFor="input-name">
                  Nom de la routine anglais
                </Label>
                <Input
                  value={nameEn}
                  onChange={(e) => setNameEn(e.target.value)}
                  className="form-control-alternative"
                  id="input-nameen"
                  placeholder="Nom de la routine anglais"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="form-control-label" htmlFor="input-name">
                  Description de la routine français
                </Label>
                <Input
                  value={descriptionFr}
                  onChange={(e) => setDescriptionFr(e.target.value)}
                  className="form-control-alternative"
                  id="input-descriptionfr"
                  placeholder="Description de la routine français"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="form-control-label" htmlFor="input-name">
                  Description de la routine anglais
                </Label>
                <Input
                  value={descriptionEn}
                  onChange={(e) => setDescriptionEn(e.target.value)}
                  className="form-control-alternative"
                  id="input-descriptionen"
                  placeholder="Description de la routine anglais"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <Select
                ref={select}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                isSearchable={true}
                name="color"
                options={exercices}
              />
            </Col>
            <Col>
              <Button
                onClick={handleAddExercice}
                color="primary"
                type="button"
                disabled={!canAddExercice}
              >
                Ajouter l'exercise
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingRight: "15px",
              paddingLeft: "15px",
              justifyContent: "center",
            }}
          >
            <RLDD
              cssClasses="example"
              items={items}
              itemRenderer={(item, index) => {
                return (
                  <div key={item.label}>
                    {index !== 0 && <BsArrowRight className="arrow" />}
                    <div className="item">
                      <TiDelete
                        className="delete"
                        onClick={() => handleDeleteItem(item)}
                      />
                      <img
                        alt={item.Label}
                        src={`${process.env.REACT_APP_API_URL}/video-image/${item.value}?time=${item.data.video_timer}`}
                      />
                      <p>{item.Label}</p>
                    </div>
                  </div>
                );
              }}
              onChange={handleRLDDChange}
            />
          </Row>
          <Row>
            <div className="finalButton">
              {!idRoutine ? (
                <>
                  <Button
                    color="danger"
                    type="button"
                    onClick={props.handleCancelCreate}
                  >
                    Annuler
                  </Button>
                  <Button color="primary" type="button" onClick={SaveRoutine}>
                    {" "}
                    Créer la routine{" "}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="danger"
                    type="button"
                    onClick={props.handleCancel}
                  >
                    Annuler
                  </Button>
                  <Button color="success" type="button" onClick={SaveRoutine}>
                    Enregistrer
                  </Button>
                </>
              )}
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default Elements;

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Spinner from "loading-screen-kraenau";
import Recoil from "recoil";
import routes from "routes.js";
import Login from "views/pages/Login.js";
import { userState } from "./../store/user";

function Admin() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const [user, setUser] = Recoil.useRecoilState(userState);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (user.role.includes("admin")) {
      setTimeout(() => {
        mainContentRef.current.scrollTop = 0;
      }, 1000);
    }
  }, [location, user]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return <Route path={prop.path} component={prop.component} key={key} />;
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user/info`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data?.data?.role) {
          data.data = {};
          data.data.role = [];
        }
        setUser(data.data);
        setLoaded(true);
        document.body.classList.remove("bg-default");
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loaded ? (
        <>
          {user.role.includes("admin") ? (
            <>
              <Sidebar
                routes={routes}
                toggleSidenav={toggleSidenav}
                sidenavOpen={sidenavOpen}
                logo={{
                  innerLink: "/",
                  imgSrc: require("assets/img/brand/logo_uSlow_coul.webp")
                    .default,
                  imgAlt: "...",
                }}
              />
              <div className="main-content" ref={mainContentRef}>
                <AdminNavbar
                  theme={getNavbarTheme()}
                  toggleSidenav={toggleSidenav}
                  sidenavOpen={sidenavOpen}
                  brandText={getBrandText(location.pathname)}
                />
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="*" to="/dashboard" />
                </Switch>
                <AdminFooter />
              </div>
              {sidenavOpen ? (
                <div className="backdrop d-xl-none" onClick={toggleSidenav} />
              ) : null}
            </>
          ) : (
            <Login />
          )}
        </>
      ) : (
        <Spinner type="square" backgroundColor="color" spinnerColor="color">
          {" "}
        </Spinner>
      )}
    </>
  );
}

export default Admin;

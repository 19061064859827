// reactstrap components
import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
// react icons import
import EditRoutine from './components/EditRoutine';
import { FaLongArrowAltRight } from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";
import { IoIosAddCircle } from "react-icons/io";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import ReactBSAlert from "react-bootstrap-sweetalert";
import '../../../assets/css/routine.css';


function Elements() {

  const [items, setItems] = useState([]);
  const [exercices, setExercices] = React.useState([]);
  const [editID, setEditID] = React.useState(null);
  const [inCreate, setInCreate] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/routine`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setItems(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [editID, inCreate]);

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/exercice?lang=fr`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setExercices(data.data.all);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = (id) => {
    setAlert(
      <ReactBSAlert
        warning
        showCancel
        confirmBtnText="Oui, supprimer!"
        confirmBtnBsStyle="danger"
        title="Etes-vous sûr ?"
        onConfirm={() => {
          setAlert(null);
          fetch(`${process.env.REACT_APP_API_URL}/routine/${id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
            .then((res) => res.json())
            .then((data) => {
              setEditID(null);
            })
            .catch((err) => {
              console.log(err);
            });
        }}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        Cette routine sera supprimé définitivement !
      </ReactBSAlert>
    );
  };

  const handelUp = (index) => {
      let temp = [...items];
      const actualOrder = temp[index].order;
      temp[index].order = items[index - 1].order;
      temp[index - 1].order = actualOrder;
      temp[index].idRoutine = temp[index]._id;
      temp[index - 1].idRoutine = temp[index - 1]._id;
      setItems([...temp]);
      fetch(`${process.env.REACT_APP_API_URL}/routine`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(temp[index])
      })
      fetch(`${process.env.REACT_APP_API_URL}/routine`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(temp[index - 1])
      })
  }

  const handelDown = (index) => {
      let temp = [...items];
      const actualOrder = temp[index].order;
      temp[index].order = items[index + 1].order;
      temp[index + 1].order = actualOrder;
      temp[index].idRoutine = temp[index]._id;
      temp[index + 1].idRoutine = temp[index + 1]._id;
      setItems([...temp]);
      fetch(`${process.env.REACT_APP_API_URL}/routine`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(temp[index])
      })
      fetch(`${process.env.REACT_APP_API_URL}/routine`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(temp[index + 1])
      })
  }


  return (
    <>
      {alert}
      <SimpleHeader name="Routine" parentName="Dashbord" />
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <h3 className="mb-0">Gestion des routines</h3>
            <Button color="primary" type="button" className='add' onClick={() => setInCreate(true)}>
              <IoIosAddCircle />&nbsp;&nbsp; Créer une routine
            </Button>
          </CardHeader>
          <CardBody>

            {

              inCreate && 
                <EditRoutine 
                  handleCancelCreate={()=> setInCreate(false)}
                  name={`Créer une routine`}
                  handleCancel={()=> setInCreate(false)}
                  order={items.length + 1}
                />

            }

            {
              items.sort((a, b) => a.order - b.order).map((item, index) => {
                if (index === editID) {
                  return (
                    <EditRoutine 
                      routine={item}
                      handleDelete={handleDelete}
                      handleCancel={()=> setEditID(null)}
                      name={`Routine  ${ index + 1 }`}
                      order={index}
                    />
                  )
                }
                return (
                  <Card className="mb-4" key={item.name.fr + item._id}>
                    <CardHeader>
                      <h3 className="mb-0">Routine  { index + 1 }</h3>
                      {
                        index !== 0 &&
                        <Button color="default" size="sm" type="button" className='up' onClick={() => handelUp(index)}>
                          <FaArrowUp/>
                        </Button>
                      }
                      {
                        index !== items.length - 1 &&
                        <Button color="default" size="sm" type="button" className='down' onClick={() => handelDown(index)}>
                          <FaArrowDown/>
                        </Button>
                      }
                      <Button color="primary" size="sm" type="button" className='edit' onClick={() => setEditID(index)}>
                        <RiEdit2Fill/> Editer
                      </Button>
                    </CardHeader>
                    <CardBody>
                    <Row>
                        <Col md="6">
                            <b>Nom français:</b> &nbsp;	&nbsp; {item.name.fr}
                        </Col>
                        <Col md="6">
                            <b>Nom Anglais:</b> &nbsp;	&nbsp; {item.name.en}
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <b>Description Français:</b> &nbsp;	&nbsp; {item.description.fr}
                        </Col>
                        <Col md="6">
                            <b>Description Anglais:</b> &nbsp;	&nbsp; {item.description.en}
                        </Col>
                    </Row>
                    <div className='exercices'>
                      {
                        exercices.length !== 0 && item.exercices.map((exercice, index) => {
                          const data = exercices.find((ex) => exercice === ex.id);
                          return (
                            <div key={data.name} className='kill'>
                            {
                              index !== 0 && <FaLongArrowAltRight className='arrows' />
                            }
                            <Row className='exercice' key={data.name}>
                              <img alt={data.name} src={`${process.env.REACT_APP_API_URL}/video-image/${exercice}?time=${data.video_timer}`}/>
                              <p>{data.name}</p>
                            </Row>
                            </div>
                          )
                        })
                      }
                    </div>


                  </CardBody>
                </Card>
                )
              })
            }
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Elements;

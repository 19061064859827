// reactstrap components
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { TbArrowBackUp } from "react-icons/tb";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";

function Exercices() {
  const [message, setMessage] = React.useState(<></>);
  const [loading, setLoading] = React.useState(false);
  const [percentage, setPercentage] = React.useState(0);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    document
      .querySelector("#video_thumbnail")
      .setAttribute("src", window.URL.createObjectURL(file));
  };

  const handleVideoChangeDemo = (e) => {
    const file = e.target.files[0];
    document
      .querySelector("#video_thumbnail_demo")
      .setAttribute("src", window.URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    setMessage(<></>);
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "name",
      JSON.stringify({ fr: e.target[0].value, en: e.target[1].value })
    );
    formData.append(
      "title",
      JSON.stringify({ fr: e.target[2].value, en: e.target[3].value })
    );
    formData.append(
      "description",
      JSON.stringify({ fr: e.target[4].value, en: e.target[5].value })
    );
    const positionType = [];
    if (e.target[6].checked) {
      positionType.push("on_the_ground");
    }
    if (e.target[7].checked) {
      positionType.push("sits_down");
    }
    if (e.target[8].checked) {
      positionType.push("stand_up");
    }
    formData.append("type", JSON.stringify(positionType));
    if (e.target[9].value === "Accessible que aux abonnés") {
      formData.append("premium", "true");
    } else {
      formData.append("premium", "false");
    }
    formData.append("video", e.target[10].files[0], "video_fr.mp4");
    formData.append("video_timer", e.target[11].value);
    formData.append("video_demo", e.target[12].files[0], "video.mp4");
    formData.append("video_demo_timer", e.target[13].value);
    formData.append("audio_fr", e.target[14].files[0], "audio_fr.mp3");
    formData.append("audio_en", e.target[15].files[0], "audio_en.mp3");
    // const data = {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //   },
    //   body: formData,
    // };
    let request = new XMLHttpRequest();

    request.open("POST", `${process.env.REACT_APP_API_URL}/exercice`);
    request.setRequestHeader(
      "Authorization",
      "Bearer " + sessionStorage.getItem("token")
    );

    // upload progress event
    request.upload.addEventListener("progress", function (e) {
      // upload progress as percentage
      let percent_completed = (e.loaded / e.total) * 100;
      // arround to 2 decimals
      percent_completed = Math.round(percent_completed * 100) / 100;
      setPercentage(percent_completed);
    });

    // request finished event
    request.addEventListener("load", function (event) {
      setLoading(false);
      const response = JSON.parse(request.response);
      setPercentage(0);

      // HTTP status message (200, 404 etc)
      console.log(response);

      // request.response holds response from the server
      if (request.status === 200) {
        setMessage(
          <UncontrolledAlert color="success" fade={false}>
            <span className="alert-inner--icon">
              <i className="ni ni-like-2" />
            </span>{" "}
            <span className="alert-inner--text">
              <strong>Success!</strong> L'exercice {e.target[0].value} a été
              créé avec succès.
            </span>
          </UncontrolledAlert>
        );
      } else {
        setMessage(
          <UncontrolledAlert color="danger">
            {`Error ${request.status}: ${response.errorType} ${response.data}`}
          </UncontrolledAlert>
        );
      }
    });

    // send POST request to server
    request.send(formData);

    // fetch(`${process.env.REACT_APP_API_URL}/exercice`, data)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setLoading(false);
    //     if (data.status === 200) {
    //       setMessage(
    //         <UncontrolledAlert color="success" fade={false}>
    //           <span className="alert-inner--icon">
    //             <i className="ni ni-like-2" />
    //           </span>{" "}
    //           <span className="alert-inner--text">
    //             <strong>Success!</strong> L'exercice {e.target[0].value} a
    //             été créé avec succès.
    //           </span>
    //         </UncontrolledAlert>
    //       );
    //     } else {
    //       setMessage(
    //         <UncontrolledAlert color="danger">
    //           {data.error}
    //         </UncontrolledAlert>
    //       );
    //     }
    //   });
  };
  return (
    <>
      <SimpleHeader name="Exercices" parentName="Ajouter" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  {message}
                  <Link
                    to="/exercices"
                    style={{ display: "flex", color: "#5e72e4" }}
                  >
                    <TbArrowBackUp style={{ fontSize: "25px" }} />
                    &nbsp;&nbsp;&nbsp;
                    <h3 className="mb-0">Ajouter un exercice</h3>
                  </Link>
                </CardHeader>
                <CardBody>
                  {/* style={{ position: "absolute", top: "-200vh" }} */}
                  <canvas
                    id="thumbnail"
                    style={{ position: "absolute", top: "-200vh" }}
                  ></canvas>
                  <canvas
                    id="thumbnail_demo"
                    style={{ position: "absolute", top: "-200vh" }}
                  ></canvas>
                  <video
                    id="video_thumbnail"
                    type="video/mp4"
                    style={{
                      position: "absolute",
                      opacity: "0",
                      userSelect: "none",
                    }}
                  >
                    <source type="video/mp4" />
                  </video>
                  <video
                    id="video_thumbnail_demo"
                    type="video/mp4"
                    style={{
                      position: "absolute",
                      opacity: "0",
                      userSelect: "none",
                    }}
                  >
                    <source type="video/mp4" />
                  </video>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name_fr"
                          >
                            Nom de l'exercice (en français)
                          </label>
                          <Input
                            id="name_fr"
                            placeholder="Nom français"
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name_en"
                          >
                            Nom de l'exercice (en anglais)
                          </label>
                          <Input
                            id="name_en"
                            placeholder="Nom anglais"
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="title_fr"
                          >
                            Titre de l'exercice (en français)
                          </label>
                          <Input
                            id="title_fr"
                            placeholder="Titre français"
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="title_en"
                          >
                            Titre de l'exercice (en anglais)
                          </label>
                          <Input
                            id="title_en"
                            placeholder="Titre anglais"
                            type="text"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="description_fr"
                          >
                            Description de l'exercice (en français)
                          </label>
                          <Input
                            id="description_fr"
                            rows="3"
                            type="textarea"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="description_en"
                          >
                            Description de l'exercice (en anglais)
                          </label>
                          <Input
                            id="description_en"
                            rows="3"
                            type="textarea"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Positions de l'exercice
                          </label>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="position1"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="position1"
                            >
                              Au sol
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="position2"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="position2"
                            >
                              Assit
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="position3"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="position3"
                            >
                              Debout
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="premium"
                          >
                            Accessibilité de l'exercice
                          </label>
                          <Input id="premium" type="select">
                            <option>Accessible que aux abonnés</option>
                            <option>Accessible à tous</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="video">
                            Video de l'exercice
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="video"
                              lang="en"
                              type="file"
                              required
                              accept="video/mp4"
                              onChange={handleVideoChange}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="video"
                            >
                              Select file
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name_fr"
                          >
                            Temps en secondes de la miniature de la video
                          </label>
                          <Input
                            id="time"
                            placeholder="0"
                            type="number"
                            required
                            min="0"
                            value="0"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="video_demo"
                          >
                            Video demo de l'exercice
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="video_demo"
                              lang="en"
                              type="file"
                              required
                              accept="video/mp4"
                              onChange={handleVideoChangeDemo}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="video_demo"
                            >
                              Select file
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name_fr"
                          >
                            Temps en secondes de la miniature de la video demo
                          </label>
                          <Input
                            id="time_demo"
                            placeholder="0"
                            type="number"
                            required
                            min="0"
                            value="0"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="audio_fr"
                          >
                            Audio de l'exercice (en français)
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="audio_fr"
                              lang="fr"
                              type="file"
                              required
                              accept="audio/mp3"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="audio_fr"
                            >
                              Select file
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="audio_en"
                          >
                            Audio de l'exercice (en anglais)
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              id="audio_en"
                              lang="en"
                              type="file"
                              required
                              accept="audio/mp3"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="audio_en"
                            >
                              Select file
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    {message}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        className="btn-icon btn-3"
                        color="primary"
                        type="submit"
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-send" />
                        </span>
                        <span className="btn-inner--text">
                          Créer l'exercice
                        </span>
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {loading ? (
                        <>
                          <TailSpin width="50" strokeColor="#FF5733" />
                          &nbsp;&nbsp;&nbsp;<h3>{percentage}%</h3>
                        </>
                      ) : null}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Exercices;

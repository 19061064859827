import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import Recoil from "recoil";
import { userState } from "./../../store/user";

function Login() {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [user, setUser] = Recoil.useRecoilState(userState);

  React.useEffect(() => {
    document.body.classList.add("bg-default");
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.target[0].value,
        password: e.target[1].value,
      }),
    };
    fetch(`${process.env.REACT_APP_API_URL}/auth/login`, data)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          if (data.data.user.role.includes("admin")) {
            sessionStorage.setItem("token", data.data.token);
            setUser(data.data.user);
            document.body.classList.remove("bg-default");
          } else {
            setErrorMessage("You are not authorized to login");
          }
        } else {
          if (typeof data.error === "string") {
            setErrorMessage(data.error);
          } else {
            setErrorMessage(JSON.stringify(data.error));
          }
        }
      });
  };

  return (
    <>
      <AuthHeader
        title="Bienvenue !"
        lead="Connectez-vous pour accéder à l'espace backoffice."
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Connexion</small>
                </div>
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" type="email" />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Mot de passe" type="password" />
                    </InputGroup>
                  </FormGroup>
                  {errorMessage !== "" ? (
                    <div className="text-center">
                      <p className="text-danger font-weight-bold">
                        {errorMessage}
                      </p>
                    </div>
                  ) : null}
                  <div className="text-center -cursor-pointer">
                    <Button className="my-4" color="info" type="submit">
                      Se connecter
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;

// reactstrap components
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { TbArrowBackUp } from "react-icons/tb";
import { TailSpin } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";

function User() {
  const [message, setMessage] = React.useState(<></>);
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(false);
  const params = useParams();

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          console.log(data.data);
          setUser(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params]);

  const handleSubmit = async (e) => {
    setMessage(<></>);
    setLoading(true);
    e.preventDefault();
    const data = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    };

    fetch(`${process.env.REACT_APP_API_URL}/user/${params.id}`, data)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === 200) {
          setMessage(
            <UncontrolledAlert color="success" fade={false}>
              <span className="alert-inner--icon">
                <i className="ni ni-like-2" />
              </span>{" "}
              <span className="alert-inner--text">
                <strong>Success!</strong> L'utilisateur {e.target[0].value} a
                été Modifier.
              </span>
            </UncontrolledAlert>
          );
        } else {
          setMessage(
            <UncontrolledAlert color="danger">{data.error}</UncontrolledAlert>
          );
        }
      });
  };
  return (
    <>
      <SimpleHeader name="Utilisateur" parentName="Editer" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  {message}
                  <Link to="/" style={{ display: "flex", color: "#5e72e4" }}>
                    <TbArrowBackUp style={{ fontSize: "25px" }} />
                    &nbsp;&nbsp;&nbsp;
                    <h3 className="mb-0">Editer l'utilisateur</h3>
                  </Link>
                </CardHeader>
                <CardBody>
                  {user && (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name_fr"
                            >
                              Email
                            </label>
                            <Input
                              id="name_fr"
                              placeholder="Nom français"
                              type="text"
                              value={user.email}
                              onChange={(e) => {
                                setUser({
                                  ...user,
                                  email: e.target.value,
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name_en"
                            >
                              Mot de passe
                            </label>
                            <Input
                              id="name_en"
                              placeholder=""
                              type="text"
                              onChange={(e) => {
                                setUser({
                                  ...user,
                                  password: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="role"
                            >
                              Premium
                            </label>
                            <Input
                              id="role"
                              type="select"
                              onChange={(e) => {
                                if (e.target.value === "Utilisateur") {
                                  setUser({
                                    ...user,
                                    role: ["user"],
                                  });
                                } else {
                                  setUser({
                                    ...user,
                                    role: ["admin"],
                                  });
                                }
                              }}
                            >
                              <option selected={!user.role.includes("admin")}>
                                Utilisateur
                              </option>
                              <option selected={user.role.includes("admin")}>
                                Administrateur
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="premium"
                            >
                              Premium
                            </label>
                            <Input
                              id="premium"
                              type="select"
                              onChange={(e) => {
                                if (e.target.value === "Accès premium") {
                                  setUser({
                                    ...user,
                                    premium: true,
                                  });
                                } else {
                                  setUser({
                                    ...user,
                                    premium: false,
                                  });
                                }
                              }}
                            >
                              <option selected={user.premium}>
                                Accès premium
                              </option>
                              <option selected={!user.premium}>
                                Non premium
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          className="btn-icon btn-3"
                          color="primary"
                          type="submit"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-send" />
                          </span>
                          <span className="btn-inner--text">
                            Modifier l'utilisateur
                          </span>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {loading ? (
                          <TailSpin width="50" strokeColor="#FF5733" />
                        ) : null}
                      </div>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default User;

// reactstrap components
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { TbArrowBackUp } from "react-icons/tb";
import { TailSpin } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";

function Exercices() {
  const [message, setMessage] = React.useState(<></>);
  const [loading, setLoading] = React.useState(false);
  const [exercices, setExercices] = React.useState(false);
  const params = useParams();

  React.useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/exercice/${params.id}?showall=true`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setExercices(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params]);

  const handleSubmit = async (e) => {
    setMessage(<></>);
    setLoading(true);
    e.preventDefault();
    const data = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(exercices),
    };

    fetch(`${process.env.REACT_APP_API_URL}/exercice/${params.id}`, data)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === 200) {
          setMessage(
            <UncontrolledAlert color="success" fade={false}>
              <span className="alert-inner--icon">
                <i className="ni ni-like-2" />
              </span>{" "}
              <span className="alert-inner--text">
                <strong>Success!</strong> L'exercice {e.target[0].value} a été
                Modifier.
              </span>
            </UncontrolledAlert>
          );
        } else {
          setMessage(
            <UncontrolledAlert color="danger">{data.error}</UncontrolledAlert>
          );
        }
      });
  };
  return (
    <>
      <SimpleHeader name="Exercices" parentName="Editer" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  {message}
                  <Link
                    to="/exercices"
                    style={{ display: "flex", color: "#5e72e4" }}
                  >
                    <TbArrowBackUp style={{ fontSize: "25px" }} />
                    &nbsp;&nbsp;&nbsp;
                    <h3 className="mb-0">Editer un exercice</h3>
                  </Link>
                </CardHeader>
                <CardBody>
                  {exercices && (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name_fr"
                            >
                              Nom de l'exercice (en français)
                            </label>
                            <Input
                              id="name_fr"
                              placeholder="Nom français"
                              type="text"
                              value={exercices.name.fr}
                              onChange={(e) => {
                                setExercices({
                                  ...exercices,
                                  name: {
                                    fr: e.target.value,
                                    en: exercices.name.en,
                                  },
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name_en"
                            >
                              Nom de l'exercice (en anglais)
                            </label>
                            <Input
                              id="name_en"
                              placeholder="Nom anglais"
                              type="text"
                              value={exercices.name.en}
                              onChange={(e) => {
                                setExercices({
                                  ...exercices,
                                  name: {
                                    fr: exercices.name.fr,
                                    en: e.target.value,
                                  },
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="title_fr"
                            >
                              Titre de l'exercice (en français)
                            </label>
                            <Input
                              id="title_fr"
                              placeholder="Titre français"
                              type="text"
                              value={exercices.title.fr}
                              onChange={(e) => {
                                setExercices({
                                  ...exercices,
                                  title: {
                                    fr: e.target.value,
                                    en: exercices.title.en,
                                  },
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="title_en"
                            >
                              Titre de l'exercice (en anglais)
                            </label>
                            <Input
                              id="title_en"
                              placeholder="Titre anglais"
                              type="text"
                              value={exercices.title.en}
                              onChange={(e) => {
                                setExercices({
                                  ...exercices,
                                  title: {
                                    fr: exercices.title.fr,
                                    en: e.target.value,
                                  },
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="description_fr"
                            >
                              Description de l'exercice (en français)
                            </label>
                            <Input
                              id="description_fr"
                              rows="3"
                              type="textarea"
                              value={exercices.description.fr}
                              onChange={(e) => {
                                setExercices({
                                  ...exercices,
                                  description: {
                                    fr: e.target.value,
                                    en: exercices.description.en,
                                  },
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="description_en"
                            >
                              Description de l'exercice (en anglais)
                            </label>
                            <Input
                              id="description_en"
                              rows="3"
                              type="textarea"
                              value={exercices.description.en}
                              onChange={(e) => {
                                setExercices({
                                  ...exercices,
                                  description: {
                                    fr: exercices.description.fr,
                                    en: e.target.value,
                                  },
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label className="form-control-label">
                              Positions de l'exercice
                            </label>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="position1"
                                type="checkbox"
                                checked={exercices.type.includes(
                                  "on_the_ground"
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setExercices({
                                      ...exercices,
                                      type: [
                                        ...exercices.type,
                                        "on_the_ground",
                                      ],
                                    });
                                  } else {
                                    setExercices({
                                      ...exercices,
                                      type: exercices.type.filter(
                                        (type) => type !== "on_the_ground"
                                      ),
                                    });
                                  }
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="position1"
                              >
                                Au sol
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="position2"
                                type="checkbox"
                                checked={exercices.type.includes("sits_down")}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setExercices({
                                      ...exercices,
                                      type: [...exercices.type, "sits_down"],
                                    });
                                  } else {
                                    setExercices({
                                      ...exercices,
                                      type: exercices.type.filter(
                                        (type) => type !== "sits_down"
                                      ),
                                    });
                                  }
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="position2"
                              >
                                Assit
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="position3"
                                type="checkbox"
                                checked={exercices.type.includes("stand_up")}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setExercices({
                                      ...exercices,
                                      type: [...exercices.type, "stand_up"],
                                    });
                                  } else {
                                    setExercices({
                                      ...exercices,
                                      type: exercices.type.filter(
                                        (type) => type !== "stand_up"
                                      ),
                                    });
                                  }
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="position3"
                              >
                                Debout
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="premium"
                            >
                              Accessibilité de l'exercice
                            </label>
                            <Input
                              id="premium"
                              type="select"
                              onChange={(e) => {
                                if (
                                  e.target.value ===
                                  "Accessible que aux abonnés"
                                ) {
                                  setExercices({
                                    ...exercices,
                                    premium: true,
                                  });
                                } else {
                                  setExercices({
                                    ...exercices,
                                    premium: false,
                                  });
                                }
                              }}
                            >
                              <option selected={exercices.premium}>
                                Accessible que aux abonnés
                              </option>
                              <option selected={!exercices.premium}>
                                Accessible à tous
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name_fr"
                            >
                              Temps en secondes de la miniature de la video
                            </label>
                            <Input
                              id="time"
                              placeholder="0"
                              type="number"
                              required
                              min="0"
                              value={exercices.video_timer}
                              onChange={(e) => {
                                setExercices({
                                  ...exercices,
                                  video_timer: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name_fr"
                            >
                              Temps en secondes de la miniature de la video demo
                            </label>
                            <Input
                              id="time_demo"
                              placeholder="0"
                              type="number"
                              required
                              min="0"
                              value={exercices.video_demo_timer}
                              onChange={(e) => {
                                setExercices({
                                  ...exercices,
                                  video_demo_timer: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          className="btn-icon btn-3"
                          color="primary"
                          type="submit"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-send" />
                          </span>
                          <span className="btn-inner--text">
                            Modifier l'exercice
                          </span>
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {loading ? (
                          <TailSpin width="50" strokeColor="#FF5733" />
                        ) : null}
                      </div>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Exercices;

// reactstrap components
import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { MdAttachMoney, MdOutlineMoneyOffCsred } from "react-icons/md";
import { TbArrowBackUp } from "react-icons/tb";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Afficher{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        utilisateurs.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function Exercices() {
  const [alert, setAlert] = React.useState(null);
  const [exercices, setExercices] = React.useState([]);
  const componentRef = React.useRef(null);

  const handleDelete = (id) => {
    setAlert(
      <ReactBSAlert
        warning
        showCancel
        confirmBtnText="Oui, supprimer!"
        confirmBtnBsStyle="danger"
        title="Etes-vous sûr ?"
        onConfirm={() => {
          setAlert(null);
          fetch(`${process.env.REACT_APP_API_URL}/exercice/${id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
            .then((res) => res.json())
            .catch((err) => {
              console.log(err);
            });
        }}
        onCancel={() => setAlert(null)}
        focusCancelBtn
      >
        Cet exercice sera supprimé définitivement !
      </ReactBSAlert>
    );
  };

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/exercice?lang=fr`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          setExercices(data.data.all);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [alert]);

  return (
    <>
      {alert}
      <SimpleHeader name="Exercices" parentName="Liste" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <Link to="/" style={{ display: "flex", color: "#5e72e4" }}>
                    <TbArrowBackUp style={{ fontSize: "25px" }} />
                    &nbsp;&nbsp;&nbsp;
                    <h3 className="mb-0">Liste des exercices</h3>
                  </Link>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    data={exercices}
                    keyField="name"
                    columns={[
                      {
                        dataField: "name",
                        text: "Nom",
                        sort: true,
                      },
                      {
                        dataField: "title",
                        text: "Titre",
                        sort: true,
                      },
                      {
                        dataField: "type",
                        text: "Position",
                        sort: true,
                        formatter: (_cellContent, row) => {
                          let result = "";
                          for (let i = 0; i < row.type.length; i++) {
                            if (row.type[i] === "on_the_ground") {
                              result += "Sur le sol, ";
                            } else if (row.type[i] === "sits_down") {
                              result += "Assis, ";
                            } else if (row.type[i] === "stand_up") {
                              result += "Debout, ";
                            }
                          }
                          return result.slice(0, -2);
                        },
                      },
                      {
                        dataField: "premium",
                        text: "Payant",
                        sort: true,
                        formatter: (_cellContent, row) => {
                          if (row.premium) {
                            return (
                              <MdAttachMoney
                                className="text-primary"
                                size={"2em"}
                              />
                            );
                          }
                          return (
                            <MdOutlineMoneyOffCsred
                              className="text-orange"
                              size={"2em"}
                            />
                          );
                        },
                      },
                      {
                        dataField: "option",
                        text: "Option",
                        sort: true,
                        formatter: (_cellContent, row) => {
                          console.log(row);
                          if (row.video_converted !== false) {
                            return (
                              <>
                                <Link to={`/editexercice/${row.id}`}>
                                  <AiFillEdit
                                    style={{ cursor: "pointer" }}
                                    className="text-primary"
                                    size={"2em"}
                                  />
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <AiFillDelete
                                  style={{ cursor: "pointer" }}
                                  className="text-red"
                                  size={"2em"}
                                  onClick={() => handleDelete(row.id)}
                                />
                              </>
                            );
                          }
                          return (
                            <div style={{ display: "flex" }}>
                              <TailSpin
                                width="20"
                                height="20"
                                strokeColor="#FF5733"
                              />
                              &nbsp;&nbsp;&nbsp;
                              <span>Conversion des videos en cours</span>
                              &nbsp;&nbsp;&nbsp;
                              <AiFillDelete
                                style={{ cursor: "pointer" }}
                                className="text-red"
                                size={"1.5em"}
                                onClick={() => handleDelete(row.id)}
                              />
                            </div>
                          );
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={12}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Exercices;
